export type AirMembershipTypeEnum = 'FREETEXT' | 'SPECIAL_KEYWORD' | 'LOYALTY_ID';
export const AirMembershipTypeConstant = {
  FREETEXT: 'FREETEXT' as AirMembershipTypeEnum,
  SPECIALKEYWORD: 'SPECIAL_KEYWORD' as AirMembershipTypeEnum,
  LOYALTYID: 'LOYALTY_ID' as AirMembershipTypeEnum
};

export type AirSeatEnum = 'A' | 'W';
export const AirSeatConstant = {
  A: 'A' as AirSeatEnum,
  W: 'W' as AirSeatEnum
};

export type SpecialEquipmentEnum = 'ABS' | 'AIR' | 'ATD' | 'BYC' | 'CAL' | 'CAM' | 'CBR' | 'CCD' | 'CLS' | 'CSB' | 'CSI' | 'CST' |
  'DAB' | 'FMR' | 'FRT' | 'HCH' | 'HCL' | 'HCR' | 'ICS' | 'LDP' | 'LFA' | 'LFF' | 'NSK' | 'NVP' | 'NVS' | 'PAB' | 'PAS' | 'PAX' |
  'PDL' | 'PHN' | 'PWS' | 'RAD' | 'RSB' | 'SBE' | 'SIX' | 'SKI' | 'SKR' | 'SKV' | 'SNO' | 'STR' | 'SUR' | 'TAP' | 'TEL' | 'TRH' |
  'TSW' | 'TVI';
export const SpecialEquipmentConstant = {
  ANTI_BLOCKING_SYSTEM: 'ABS' as SpecialEquipmentEnum,
  AIR_CONDITIONING: 'AIR' as SpecialEquipmentEnum,
  ANTI_THEFT_DEVICE: 'ATD' as SpecialEquipmentEnum,
  BICYCLE_RACK: 'BYC' as SpecialEquipmentEnum,
  CAR_ALARM: 'CAL' as SpecialEquipmentEnum,
  CAMCORDER: 'CAM' as SpecialEquipmentEnum,
  CITIZENS_BAND_RADIO: 'CBR' as SpecialEquipmentEnum,
  COMPUTERIZED_DRIVING_DIRECT: 'CCD' as SpecialEquipmentEnum,
  CENTRAL_LOCKING_SYSTEM: 'CLS' as SpecialEquipmentEnum,
  CHILD_SEAT_FOR_BABY: 'CSB' as SpecialEquipmentEnum,
  CHILD_SEAT_FOR_INFANT: 'CSI' as SpecialEquipmentEnum,
  CHILD_SEAT_FOR_TODDLER: 'CST' as SpecialEquipmentEnum,
  DRIVER_SIDE_ARIBAG: 'DAB' as SpecialEquipmentEnum,
  FM_RADIO: 'FMR' as SpecialEquipmentEnum,
  FRONT_WHEEL_DRIVE: 'FRT' as SpecialEquipmentEnum,
  HATCHBACK_CAR: 'HCH' as SpecialEquipmentEnum,
  HAND_CONTROLS_ON_LEFT: 'HCL' as SpecialEquipmentEnum,
  HAND_CONTROLS_ON_RIGHT: 'HCR' as SpecialEquipmentEnum,
  INTEGRATED_CHILD_SAFETY_SEAT: 'ICS' as SpecialEquipmentEnum,
  LASERDISC_PLAYER: 'LDP' as SpecialEquipmentEnum,
  LEFT_FOOT_ACCELERATOR: 'LFA' as SpecialEquipmentEnum,
  LEAD_FREE_FUEL: 'LFF' as SpecialEquipmentEnum,
  NON_SMOKER_CAR: 'NSK' as SpecialEquipmentEnum,
  NAVIGATIONAL_SYSTEM_PLUS_PHONE: 'NVP' as SpecialEquipmentEnum,
  NAVIGATIONAL_SYSTEM: 'NVS' as SpecialEquipmentEnum,
  PASSENGER_SIDE_AIRBAG: 'PAB' as SpecialEquipmentEnum,
  POWER_ASSISTED_STEERING: 'PAS' as SpecialEquipmentEnum,
  NUMBER_OF_PASSENGERS: 'PAX' as SpecialEquipmentEnum,
  POWER_DOOR_LOCKS: 'PDL' as SpecialEquipmentEnum,
  MOBILE_PHONE: 'PHN' as SpecialEquipmentEnum,
  POWER_WINDOWS: 'PWS' as SpecialEquipmentEnum,
  RADIO: 'RAD' as SpecialEquipmentEnum,
  REAR_SEAT_BELTS: 'RSB' as SpecialEquipmentEnum,
  SEAT_BELT_EXTENDERS: 'SBE' as SpecialEquipmentEnum,
  SIX_PASSENGER_CAR: 'SIX' as SpecialEquipmentEnum,
  SKI_RENTAL: 'SKI' as SpecialEquipmentEnum,
  SKI_RACK: 'SKR' as SpecialEquipmentEnum,
  SKI_EQUIPPED_VEHICLE: 'SKV' as SpecialEquipmentEnum,
  SNOW_CHAINS: 'SNO' as SpecialEquipmentEnum,
  WINTER_TYRES: 'STR' as SpecialEquipmentEnum,
  SUN_ROOF: 'SUR' as SpecialEquipmentEnum,
  CASSETTE_TAPE: 'TAP' as SpecialEquipmentEnum,
  CAR_TELEPHONE: 'TEL' as SpecialEquipmentEnum,
  TRAILER_HITCH: 'TRH' as SpecialEquipmentEnum,
  TILT_STEERING_WHEEL: 'TSW' as SpecialEquipmentEnum,
  TELEVISION: 'TVI' as SpecialEquipmentEnum
};


export type CreditCardTypeEnum = 'DC' | 'AX' | 'VI' | 'CA' | 'TP' | 'JC' | 'DS';
export const CreditCardTypeConstant = {
  DC: 'DC' as CreditCardTypeEnum,
  AX: 'AX' as CreditCardTypeEnum,
  VI: 'VI' as CreditCardTypeEnum,
  CA: 'CA' as CreditCardTypeEnum,
  TP: 'TP' as CreditCardTypeEnum,
  JC: 'JC' as CreditCardTypeEnum,
  DS: 'DS' as CreditCardTypeEnum
};

export type CreditcardUsageEnum = 'CHOOSE' | 'ALWAYS_USE_COMPANYCARD' | 'NEVER_USE_COMPANYCARD';
export const CreditcardUsageConstant = {
  CHOOSE: 'CHOOSE' as CreditcardUsageEnum,
  ALWAYSUSECOMPANYCARD: 'ALWAYS_USE_COMPANYCARD' as CreditcardUsageEnum,
  NEVERUSECOMPANYCARD: 'NEVER_USE_COMPANYCARD' as CreditcardUsageEnum
};

export type TravellerFopOptionEnum = 'FP_AND_GUARANTEE' | 'GUARANTEE_ONLY' | 'NONE';
export const TravellerFopOptionConstant = {
  FPANDGUARANTEE: 'FP_AND_GUARANTEE' as TravellerFopOptionEnum,
  GUARANTEEONLY: 'GUARANTEE_ONLY' as TravellerFopOptionEnum,
  NONE: 'NONE' as TravellerFopOptionEnum
};

export type PaymentOptionTypeEnum = 'none' | 'add-fop' | 'own-card' | 'company-card';
export const PaymentOptionTypeConstant = {
  NONE: 'none' as PaymentOptionTypeEnum,
  ADD_FOP: 'add-fop' as PaymentOptionTypeEnum,
  OWN_CARD: 'own-card' as PaymentOptionTypeEnum,
  COMPANY_CARD: 'company-card' as PaymentOptionTypeEnum,
}

export type AdditionalFormOfPaymentEnum = 'UNDEFINED' | 'INVOICE' | 'CASH' | 'LSV';
export const AdditionalFormOfPaymentConstant = {
  UNDEFINED: 'UNDEFINED' as AdditionalFormOfPaymentEnum,
  INVOICE: 'INVOICE' as AdditionalFormOfPaymentEnum,
  CASH: 'CASH' as AdditionalFormOfPaymentEnum,
  LSV: 'LSV' as AdditionalFormOfPaymentEnum,
}

export type RailIdentificationTypeEnum = 'none' | 'personal-credit-card' | 'rail-card' | 'bank-card';
export const RailIdentificationTypeConstant = {
  NONE: 'none' as RailIdentificationTypeEnum,
  PERSONALCREDITCARD: 'personal-credit-card' as RailIdentificationTypeEnum,
  RAILCARD: 'rail-card' as RailIdentificationTypeEnum,
  BANKCARD: 'bank-card' as RailIdentificationTypeEnum
};

export type FeatureRailEnum = 'AMADEUS_GERMANY' | 'AMADEUS_RAIL';
export const FeatureRailConstant = {
  AMADEUS_GERMANY: 'AMADEUS_GERMANY' as FeatureRailEnum,
  AMADEUS_RAIL: 'AMADEUS_RAIL' as FeatureRailEnum
};

export type RailMembershipClassEnum = 'UNDEFINED' | 'FIRST' | 'SECOND';
export const RailMembershipClassConstant = {
  UNDEFINED: 'UNDEFINED' as RailMembershipClassEnum,
  FIRST: 'FIRST' as RailMembershipClassEnum,
  SECOND: 'SECOND' as RailMembershipClassEnum
};

export type RailPreferencesTicketDeliveryEnum = 'UNDEFINED' | 'PAPERTICKET' | 'BAHNTIX' | 'ONLINE';
export const RailPreferencesTicketDeliveryConstant = {
  UNDEFINED: 'UNDEFINED' as RailPreferencesTicketDeliveryEnum,
  PAPERTICKET: 'PAPERTICKET' as RailPreferencesTicketDeliveryEnum,
  BAHNTIX: 'BAHNTIX' as RailPreferencesTicketDeliveryEnum,
  ONLINE: 'ONLINE' as RailPreferencesTicketDeliveryEnum
};
export type RailPreferencesWagonTypeEnum = 'UNDEFINED' | 'PREFER_WIDEBODY' | 'REQUIRE_WIDEBODY' | 'COMPARTMENT' | 'TABLE' | 'SINGLE';
export const RailPreferencesWagonTypeConstant = {
  UNDEFINED: 'UNDEFINED' as RailPreferencesWagonTypeEnum,
  PREFERWIDEBODY: 'PREFER_WIDEBODY' as RailPreferencesWagonTypeEnum,
  REQUIREWIDEBODY: 'REQUIRE_WIDEBODY' as RailPreferencesWagonTypeEnum,
  COMPARTMENT: 'COMPARTMENT' as RailPreferencesWagonTypeEnum,
  TABLE: 'TABLE' as RailPreferencesWagonTypeEnum,
  SINGLE: 'SINGLE' as RailPreferencesWagonTypeEnum
};
export type RailPreferencesWagonExtraTypeEnum = 'UNDEFINED' | 'PHONEAREA' | 'QUIETAREA';
export const RailPreferencesWagonExtraTypeConstant = {
  UNDEFINED: 'UNDEFINED' as RailPreferencesWagonExtraTypeEnum,
  PHONEAREA: 'PHONEAREA' as RailPreferencesWagonExtraTypeEnum,
  QUIETAREA: 'QUIETAREA' as RailPreferencesWagonExtraTypeEnum
};
export type RailPreferencesSeatEnum = 'W' | 'A';
export const RailPreferencesSeatConstant = {
  WINDOW: 'W' as RailPreferencesSeatEnum,
  AISLE: 'A' as RailPreferencesSeatEnum
};

export type DbiTypeEnum =
  'COSTCENTER'
  | 'COSTCENTER2'
  | 'PERSONAL'
  | 'PROJECT'
  | 'PERMIT'
  | 'REQUEST'
  | 'BUDGET'
  | 'GROUP'
  | 'INTERNALREF1'
  | 'INTERNALREF2'
  | 'INHOUSEINFO';
export const DbiTypeConstant = {
  COSTCENTER: 'COSTCENTER' as DbiTypeEnum,
  COSTCENTER2: 'COSTCENTER2' as DbiTypeEnum,
  PERSONAL: 'PERSONAL' as DbiTypeEnum,
  PROJECT: 'PROJECT' as DbiTypeEnum,
  PERMIT: 'PERMIT' as DbiTypeEnum,
  REQUEST: 'REQUEST' as DbiTypeEnum,
  BUDGET: 'BUDGET' as DbiTypeEnum,
  GROUP: 'GROUP' as DbiTypeEnum,
  INTERNALREF1: 'INTERNALREF1' as DbiTypeEnum,
  INTERNALREF2: 'INTERNALREF2' as DbiTypeEnum,
  INHOUSEINFO: 'INHOUSEINFO' as DbiTypeEnum
};

export type PublishingTypeEnum = 'ALL_PROFILES' | 'ERRORS';
export const PublishingTypeConstant = {
  ALL_PROFILES: 'ALL_PROFILES' as PublishingTypeEnum,
  ERRORS: 'ERRORS' as PublishingTypeEnum
}

export type ProfileTypeEnum = 'TRAVELLER' | 'CORPORATE' | 'STAFF' | 'AGENCY';
export const ProfileTypeConstant = {
  TRAVELLER: 'TRAVELLER' as ProfileTypeEnum,
  CORPORATE: 'CORPORATE' as ProfileTypeEnum,
  STAFF: 'STAFF' as ProfileTypeEnum,
  AGENCY: 'AGENCY' as ProfileTypeEnum
};

export type ContactTypeEnum = 'ARRANGER' | 'APPROVER' | 'EMERGENCY' | 'PASSENGER';
export const ContactTypeConstant = {
  ARRANGER: 'ARRANGER' as ContactTypeEnum,
  APPROVER: 'APPROVER' as ContactTypeEnum,
  EMERGENCY: 'EMERGENCY' as ContactTypeEnum,
  PASSENGER: 'PASSENGER' as ContactTypeEnum
}

export type ResidentAreaEnum = 'CANARY' | 'BALEARIC' | 'MELILLA' | 'ANDALUSIA' | 'UNDEFINED';
export const ResidentAreaConstant = {
  CANARY: 'CANARY' as ResidentAreaEnum,
  BALEARIC: 'BALEARIC' as ResidentAreaEnum,
  MELILLA: 'MELILLA' as ResidentAreaEnum,
  ANDALUSIA: 'ANDALUSIA' as ResidentAreaEnum
};
export type ResidentCardTypeEnum = 'NATIONAL_ID' | 'RESIDENT_CARD' | 'MINOR_RESIDENT' | 'TEMP_RESIDENT' | 'UNDEFINED';
export const ResidentCardTypeConstant = {
  NATIONALID: 'NATIONAL_ID' as ResidentCardTypeEnum,
  RESIDENTCARD: 'RESIDENT_CARD' as ResidentCardTypeEnum,
  MINORRESIDENT: 'MINOR_RESIDENT' as ResidentCardTypeEnum,
  TEMPRESIDENT: 'TEMP_RESIDENT' as ResidentCardTypeEnum
};

export type FieldTypeEnum = 'INPUT' | 'TEXTAREA' | 'SELECT';
export const FieldTypeConstant = {
  INPUT: 'INPUT' as FieldTypeEnum,
  TEXTAREA: 'TEXTAREA' as FieldTypeEnum,
  SELECT: 'SELECT' as FieldTypeEnum
};

export type ElectiveEnum = 'OPTIONAL' | 'MANDATORY' | 'COMPANY_SPECIFIC' | 'HIDDEN';
export const ElectiveConstant = {
  OPTIONAL: 'OPTIONAL' as ElectiveEnum,
  MANDATORY: 'MANDATORY' as ElectiveEnum,
  COMPANYSPECIFIC: 'COMPANY_SPECIFIC' as ElectiveEnum,
  HIDDEN: 'HIDDEN' as ElectiveEnum
};

export type GroupTypeEnum = 'INTEGRATED' | 'CUSTOM';
export const GroupTypeConstant = {
  INTEGRATED: 'INTEGRATED' as GroupTypeEnum,
  CUSTOM: 'CUSTOM' as GroupTypeEnum
};

export type AffectedUserGroupEnum = 'ENTIRE_AGENCY' | 'COMPANY_ADMIN_AND_TRAVELLER' | 'COMPANY_ADMIN_ONLY' | 'TRAVELLER';
export const AffectedUserGroupConstant = {
  ENTIRE_AGENCY: 'ENTIRE_AGENCY' as AffectedUserGroupEnum,
  COMPANY_ADMIN_AND_TRAVELLER: 'COMPANY_ADMIN_AND_TRAVELLER' as AffectedUserGroupEnum,
  COMPANY_ADMIN_ONLY: 'COMPANY_ADMIN_ONLY' as AffectedUserGroupEnum,
  TRAVELLER: 'TRAVELLER' as AffectedUserGroupEnum
};

export type FieldDisplayTypeEnum = 'OPTIONAL' | 'MANDATORY' | 'HIDDEN';
export const FieldDisplayTypeConstant = {
  OPTIONAL: 'OPTIONAL' as FieldDisplayTypeEnum,
  MANDATORY: 'MANDATORY' as FieldDisplayTypeEnum,
  HIDDEN: 'HIDDEN' as FieldDisplayTypeEnum,
  READONLY: 'READ_ONLY' as FieldDisplayTypeEnum
};

export type RoleEnum = 'ROLE_ADMIN' | 'ROLE_AGENCY_MANAGER' | 'ROLE_COMPANY_MANAGER' | 'ROLE_TRAVELLER';
export const RoleConstant = {
  ADMIN: 'ROLE_ADMIN' as RoleEnum,
  AGENCYMANAGER: 'ROLE_AGENCY_MANAGER' as RoleEnum,
  COMPANYMANAGER: 'ROLE_COMPANY_MANAGER' as RoleEnum,
  TRAVELLER: 'ROLE_TRAVELLER' as RoleEnum
};

export type SupportFunctionEnum = 'SYSTEM_ADMIN' | 'CIRCLE_ADMIN' | 'AGENCY_ADMIN' | 'COMPANY_ADMIN';
export const SupportFunctionConstant = {
  SYSTEMADMIN: 'SYSTEM_ADMIN' as SupportFunctionEnum,
  CIRCLEADMIN: 'CIRCLE_ADMIN' as SupportFunctionEnum,
  AGENCYADMIN: 'AGENCY_ADMIN' as SupportFunctionEnum,
  COMPANYADMIN: 'COMPANY_ADMIN' as SupportFunctionEnum
};

export type TravelGroupAllocationRoleEnum =
  'CUSTOM_ADMINISTRATOR'
  | 'PROFILE_MANAGER'
  | 'PRINCIPAL_TRAVEL_ARRANGER'
  | 'TRAVEL_ARRANGER'
  | 'TRAVEL_APPROVER'
  | 'TRAVELER'
  | 'USER'
  | 'APPOINTEDTRAVEL_ARRANGER'
  | 'TRAVEL_ARRANGER_RESTRICTED_PM';
export const TravelGroupAllocationRoleConstant = {
  CUSTOMADMINISTRATOR: 'CUSTOM_ADMINISTRATOR' as TravelGroupAllocationRoleEnum,
  PROFILEMANAGER: 'PROFILE_MANAGER' as TravelGroupAllocationRoleEnum,
  PRINCIPALTRAVELARRANGER: 'PRINCIPAL_TRAVEL_ARRANGER' as TravelGroupAllocationRoleEnum,
  TRAVELARRANGER: 'TRAVEL_ARRANGER' as TravelGroupAllocationRoleEnum,
  TRAVELAPPROVER: 'TRAVEL_APPROVER' as TravelGroupAllocationRoleEnum,
  TRAVELER: 'TRAVELER' as TravelGroupAllocationRoleEnum,
  USER: 'USER' as TravelGroupAllocationRoleEnum,
  APPOINTEDTRAVELARRANGER: 'APPOINTEDTRAVEL_ARRANGER' as TravelGroupAllocationRoleEnum,
  TRAVELARRANGERRESTRICTEDPM: 'TRAVEL_ARRANGER_RESTRICTED_PM' as TravelGroupAllocationRoleEnum
};

export type ProfilePublishStateFaultIndicatorEnum = 'OK' | 'QUEUED' | 'INTENTIONALLY_NOT_PUBLISHED' | 'TIMESTAMP_ISSUE' | 'GENERAL_ERROR';
export const ProfilePublishStateFaultIndicatorConstant = {
  OK: 'OK' as ProfilePublishStateFaultIndicatorEnum,
  QUEUED: 'QUEUED' as ProfilePublishStateFaultIndicatorEnum,
  INTENTIONALLY_NOT_PUBLISHED: 'INTENTIONALLY_NOT_PUBLISHED' as ProfilePublishStateFaultIndicatorEnum,
  TIMESTAMP_ISSUE: 'TIMESTAMP_ISSUE' as ProfilePublishStateFaultIndicatorEnum,
  GENERAL_ERROR: 'GENERAL_ERROR' as ProfilePublishStateFaultIndicatorEnum
};

export type VisaExportTypeEnum = 'SINGLE' | 'DOUBLE' | 'MULTIPLE';
export const VisaExportTypeEnumConstant = {
  SINGLE: 'SINGLE' as VisaExportTypeEnum,
  DOUBLE: 'DOUBLE' as VisaExportTypeEnum,
  MULTIPLE: 'MULTIPLE' as VisaExportTypeEnum
};

//used for tokenization context (company reference data)
export type PciComplianceTypeEnum = 'NONE' | 'DATATRANS' | 'MIDOCO';
export const PciComplianceTypeConstant = {
  NONE: 'NONE' as PciComplianceTypeEnum,
  DATATRANS: 'DATATRANS' as PciComplianceTypeEnum,
  MIDOCO: 'MIDOCO' as PciComplianceTypeEnum
};

//use for agency settings (agency main form)
export type PciComplianceJsonSubTypeEnum = 'none' | 'datatrans' | 'midoco';
export const PciComplianceJsonSubTypeConstant = {
  NONE: 'none' as PciComplianceJsonSubTypeEnum,
  DATATRANS: 'datatrans' as PciComplianceJsonSubTypeEnum,
  MIDOCO: 'midoco' as PciComplianceJsonSubTypeEnum
};

export type AdminShareOptionEnum = 'NONE' | 'ARRANGER' | 'APPROVER' | 'ALL';
export const AdminShareOptionConstant = {
  NONE: 'NONE' as AdminShareOptionEnum,
  ARRANGER: 'ARRANGER' as AdminShareOptionEnum,
  APPROVER: 'APPROVER' as AdminShareOptionEnum,
  ALL: 'ALL' as AdminShareOptionEnum
};

export type EmailDeliveryEnum = 'EMAIL' | 'EMAILPLUS';
export const EmailDeliveryConstant = {
  EMAIL: 'EMAIL' as EmailDeliveryEnum,
  EMAILPLUS: 'EMAILPLUS' as EmailDeliveryEnum
};

export type ProfileRemindersOptionEnum = 'OFF' | 'ON' | 'ON_IGNORE_SEND_CREDENTIALS';
export const ProfileRemindersOptionConstant = {
  OFF: 'OFF' as ProfileRemindersOptionEnum,
  ON: 'ON' as ProfileRemindersOptionEnum,
  ON_IGNORE_SEND_CREDENTIALS: 'ON_IGNORE_SEND_CREDENTIALS' as ProfileRemindersOptionEnum
}

export type UserInterfaceColorTypeEnum =
  | 'NAVBAR_BACKGROUND'
  | 'NAVBAR_TEXT'
  | 'NAVBAR_TEXT_ACTIVE'
  | 'BUTTON_PRIMARY_BACKGROUND'
  | 'BUTTON_PRIMARY_BACKGROUND_HOVER'
  | 'BUTTON_PRIMARY_TEXT'
  | 'BUTTON_PRIMARY_TEXT_HOVER'
  | 'BUTTON_SECONDARY_BACKGROUND'
  | 'BUTTON_SECONDARY_BACKGROUND_HOVER'
  | 'BUTTON_SECONDARY_TEXT'
  | 'BUTTON_SECONDARY_TEXT_HOVER'
  | 'BUTTON_OUTLINESECONDARY_BACKGROUND'
  | 'BUTTON_OUTLINESECONDARY_BACKGROUND_HOVER'
  | 'BUTTON_OUTLINESECONDARY_TEXT'
  | 'BUTTON_OUTLINESECONDARY_TEXT_HOVER'
  | 'BUTTON_DANGER_BACKGROUND'
  | 'BUTTON_DANGER_BACKGROUND_HOVER'
  | 'BUTTON_DANGER_TEXT'
  | 'BUTTON_DANGER_TEXT_HOVER'
  | 'ERROR_FIELD'
  | 'BACKGROUND'
  | 'CARD'
  | 'CARD_TEXT'
  | 'ACCORDION_EXPANDED_TEXT'
  | 'ACCORDION_EXPANDED_BACKGROUND'
  | 'PUBLISHING_ERROR'
  | 'PUBLISHING_WARNING'
  | 'PUBLISHING_OK'
  | 'ALERT_SUCCESS_BACKGROUND'
  | 'ALERT_SUCCESS_TEXT'
  | 'ALERT_WARNING_BACKGROUND'
  | 'ALERT_WARNING_TEXT'
  | 'ALERT_ERROR_BACKGROUND'
  | 'ALERT_ERROR_TEXT';
export const UserInterfaceColorTypeConstant = {
  NAVBAR_BACKGROUND: 'NAVBAR_BACKGROUND' as UserInterfaceColorTypeEnum,
  NAVBAR_TEXT: 'NAVBAR_TEXT' as UserInterfaceColorTypeEnum,
  NAVBAR_TEXT_ACTIVE: 'NAVBAR_TEXT_ACTIVE' as UserInterfaceColorTypeEnum,
  BUTTON_PRIMARY_BACKGROUND: 'BUTTON_PRIMARY_BACKGROUND' as UserInterfaceColorTypeEnum,
  BUTTON_PRIMARY_BACKGROUND_HOVER: 'BUTTON_PRIMARY_BACKGROUND_HOVER' as UserInterfaceColorTypeEnum,
  BUTTON_PRIMARY_TEXT: 'BUTTON_PRIMARY_TEXT' as UserInterfaceColorTypeEnum,
  BUTTON_PRIMARY_TEXT_HOVER: 'BUTTON_PRIMARY_TEXT_HOVER' as UserInterfaceColorTypeEnum,
  BUTTON_SECONDARY_BACKGROUND: 'BUTTON_SECONDARY_BACKGROUND' as UserInterfaceColorTypeEnum,
  BUTTON_SECONDARY_BACKGROUND_HOVER: 'BUTTON_SECONDARY_BACKGROUND_HOVER' as UserInterfaceColorTypeEnum,
  BUTTON_SECONDARY_TEXT: 'BUTTON_SECONDARY_TEXT' as UserInterfaceColorTypeEnum,
  BUTTON_SECONDARY_TEXT_HOVER: 'BUTTON_SECONDARY_TEXT_HOVER' as UserInterfaceColorTypeEnum,
  BUTTON_OUTLINESECONDARY_BACKGROUND: 'BUTTON_OUTLINESECONDARY_BACKGROUND' as UserInterfaceColorTypeEnum,
  BUTTON_OUTLINESECONDARY_BACKGROUND_HOVER: 'BUTTON_OUTLINESECONDARY_BACKGROUND_HOVER' as UserInterfaceColorTypeEnum,
  BUTTON_OUTLINESECONDARY_TEXT: 'BUTTON_OUTLINESECONDARY_TEXT' as UserInterfaceColorTypeEnum,
  BUTTON_OUTLINESECONDARY_TEXT_HOVER: 'BUTTON_OUTLINESECONDARY_TEXT_HOVER' as UserInterfaceColorTypeEnum,
  BUTTON_DANGER_BACKGROUND: 'BUTTON_DANGER_BACKGROUND' as UserInterfaceColorTypeEnum,
  BUTTON_DANGER_BACKGROUND_HOVER: 'BUTTON_DANGER_BACKGROUND_HOVER' as UserInterfaceColorTypeEnum,
  BUTTON_DANGER_TEXT: 'BUTTON_DANGER_TEXT' as UserInterfaceColorTypeEnum,
  BUTTON_DANGER_TEXT_HOVER: 'BUTTON_DANGER_TEXT_HOVER' as UserInterfaceColorTypeEnum,
  ERROR_FIELD: 'ERROR_FIELD' as UserInterfaceColorTypeEnum,
  BACKGROUND: 'BACKGROUND' as UserInterfaceColorTypeEnum,
  CARD: 'CARD' as UserInterfaceColorTypeEnum,
  CARD_TEXT: 'CARD_TEXT' as UserInterfaceColorTypeEnum,
  ACCORDION_EXPANDED_TEXT: 'ACCORDION_EXPANDED_TEXT' as UserInterfaceColorTypeEnum,
  ACCORDION_EXPANDED_BACKGROUND: 'ACCORDION_EXPANDED_BACKGROUND' as UserInterfaceColorTypeEnum,
  PUBLISHING_ERROR: 'PUBLISHING_ERROR' as UserInterfaceColorTypeEnum,
  PUBLISHING_WARNING: 'PUBLISHING_WARNING' as UserInterfaceColorTypeEnum,
  PUBLISHING_OK: 'PUBLISHING_OK' as UserInterfaceColorTypeEnum,
  ALERT_SUCCESS_BACKGROUND: 'ALERT_SUCCESS_BACKGROUND' as UserInterfaceColorTypeEnum,
  ALERT_SUCCESS_TEXT: 'ALERT_SUCCESS_TEXT' as UserInterfaceColorTypeEnum,
  ALERT_WARNING_BACKGROUND: 'ALERT_WARNING_BACKGROUND' as UserInterfaceColorTypeEnum,
  ALERT_WARNING_TEXT: 'ALERT_WARNING_TEXT' as UserInterfaceColorTypeEnum,
  ALERT_ERROR_BACKGROUND: 'ALERT_ERROR_BACKGROUND' as UserInterfaceColorTypeEnum,
  ALERT_ERROR_TEXT: 'ALERT_ERROR_TEXT' as UserInterfaceColorTypeEnum,
}

export type SexTypeEnum = 'UNDEF' | 'FEMALE' | 'MALE' | 'NON_BINARY';
export const SexTypeConstant = {
  UNDEF: 'UNDEF' as SexTypeEnum,
  FEMALE: 'FEMALE' as SexTypeEnum,
  MALE: 'MALE' as SexTypeEnum,
  NON_BINARY: 'NON_BINARY' as SexTypeEnum
};

export type CommonTitleEnum = 'MRS' | 'MR' | 'MS';
export const CommonTitleConstant = {
  MRS: 'MRS' as CommonTitleEnum,
  MR: 'MR' as CommonTitleEnum,
  MS: 'MS' as CommonTitleEnum
};

export type TitleStructureEnum = 'COMMON_AND_FORMAL' | 'COMPOUND';
export const TitleStructureConstant = {
  COMMON_AND_FORMAL: 'COMMON_AND_FORMAL' as TitleStructureEnum,
  COMPOUND: 'COMPOUND' as TitleStructureEnum
};

export enum ReferencedCreditCardDropdown {
  WEB_CARD = 'WEB_CARD',
  HOTEL_GUARANTEE = 'HOTEL_GUARANTEE',
  CAR_GUARANTEE = 'CAR_GUARANTEE',
  RAIL_FORM_OF_PAYMENT = 'RAIL_FORM_OF_PAYMENT',
  RAIL_IDENTIFICATION = 'RAIL_IDENTIFICATION'
}

export type PublishTargetSystemEnum = 'ARGO' | 'CONCUR' | 'CONFERMA' | 'CSX' | 'CYTRIC' | 'DEEM' | 'EVOLVI' | 'FARELOGIX' |
  'GALILEO_WS' | 'GENERIC_HTTP' | 'GETTHERE' | 'KDS_NEO' | 'SABRE' | 'TRAINLINE' | 'TRAMADA' | 'VIBE' | 'WORLDSPAN';
export const PublishTargetSystemConstant = {
  ARGO: 'ARGO' as PublishTargetSystemEnum,
  CONCUR: 'CONCUR' as PublishTargetSystemEnum,
  CONFERMA: 'CONFERMA' as PublishTargetSystemEnum,
  CSX: 'CSX' as PublishTargetSystemEnum,
  CYTRIC: 'CYTRIC' as PublishTargetSystemEnum,
  DEEM: 'DEEM' as PublishTargetSystemEnum,
  EVOLVI: 'EVOLVI' as PublishTargetSystemEnum,
  FARELOGIX: 'FARELOGIX' as PublishTargetSystemEnum,
  GALILEO_WS: 'GALILEO_WS' as PublishTargetSystemEnum,
  GENERIC_HTTP: 'GENERIC_HTTP' as PublishTargetSystemEnum,
  GETTHERE: 'GETTHERE' as PublishTargetSystemEnum,
  KDS_NEO: 'KDS_NEO' as PublishTargetSystemEnum,
  SABRE: 'SABRE' as PublishTargetSystemEnum,
  TRAINLINE: 'TRAINLINE' as PublishTargetSystemEnum,
  TRAMADA: 'TRAMADA' as PublishTargetSystemEnum,
  VIBE: 'VIBE' as PublishTargetSystemEnum,
  WORLDSPAN: 'WORLDSPAN' as PublishTargetSystemEnum
};
