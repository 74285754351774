import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '@environments/environment';
import { CompanyDetailResponse } from '@shared/models/company-detail-response';
import { CompanyProfileSearchResponse } from '@shared/models/company-profile-search-response';
import { CompanyReferenceData } from '@shared/models/company-referencedata';
import { ProfileTypeEnum } from '@shared/models/types.enum';
import { GenericSetupResponse } from '@shared/models/generic-setup-response';
import { CompanyCreateUpdateRequest } from '@models/company-create-update-request';
import { SelfRegistrationLink } from '@models/self-registration-link';
import { CompanySearchResult } from '@models/company-search-result';
import { FormUtils } from '@shared/form-utils.class';
import { SelfRegistrationLinkGenerationRequest } from '@models/self-registration-link-generation-request';
import { ProfilePublishState } from '@models/profile-publish-state';
import { AutoCompletableDataEntry } from '@models/auto-completable-data-entry';
import { GenericFieldWizardDataEntry } from '@shared/models/generic-field-wizard-data-entry';
import { CompanyDeleteNumbers } from '@models/company-delete-numbers';
import { CompanyAdministratorDetail } from '@models/company-administrator-detail';

export enum CompanySection {
  AGENCY_INFO = 'AGENCY_INFO',
  CONTACT_DATA = 'CONTACT_DATA',
  MEMBERSHIPS = 'MEMBERSHIPS',
  GENERIC_FIELD_VALUES = 'GENERIC_FIELD_VALUES',
  CREDIT_CARDS = 'CREDIT_CARDS',
  COMMENT = 'COMMENT',
  PARENT_COMPANY = 'PARENT_COMPANY',
  HISTORY = 'HISTORY',
  PUBLISH_STATES = 'PUBLISH_STATES',
  INTERFACE_SETUP = 'INTERFACE_SETUP',
  TRAVELLER_SETTINGS = 'TRAVELLER_SETTINGS',
  GENERAL_SETTINGS = 'GENERAL_SETTINGS'
}

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private http: HttpClient) {
  }

  search(params: {
    q: string;
    page?: number;
    pageSize?: number;
    includeDetails?: boolean;
    detailSections?: CompanySection[]
  }): Observable<CompanyProfileSearchResponse> {
    let httpParams = new HttpParams()
      .set('q', params.q)
      .set('pageSize', params.pageSize || 25);
    if (params.page !== undefined) {
      httpParams = httpParams.set('page', params.page);
    }
    if (params.includeDetails) {
      httpParams = httpParams.set('includeDetails', params.includeDetails);
      if (params.detailSections) {
        httpParams = httpParams.set('detailSections', params.detailSections.join(','));
      }
    }

    return this.http.get<CompanyProfileSearchResponse>(`${environment.apiBaseUrl}/api/v1/profiles/companies`, { params: httpParams });
  }

  read(uuid: string): Observable<CompanyDetailResponse> {
    return this.http.get<CompanyDetailResponse>(`${environment.apiBaseUrl}/api/v1/profiles/company/${uuid}`);
  }

  getNewCompany(templateUuid?: string): Observable<CompanyDetailResponse> {
    let httpParams = new HttpParams();
    if (templateUuid) {
      httpParams = httpParams.set('tmpl', templateUuid);
    }
    return this.http.get<CompanyDetailResponse>(`${environment.apiBaseUrl}/api/v1/profiles/company/new`, { params: httpParams });
  }

  getReferencedata(uuid: string): Observable<CompanyReferenceData> {
    return this.http.get<CompanyReferenceData>(`${environment.apiBaseUrl}/api/v1/profiles/company/${uuid}/referencedata`);
  }

  save(uuid: string | undefined, request: CompanyCreateUpdateRequest): Observable<CompanyDetailResponse> {
    FormUtils.prepareCreditCardsFormForUpdate(request.data?.creditCardInfo);
    const httpParams = new HttpParams().set('returnAddSections', Object.values(CompanySection).join(','));
    if (uuid) {
      return this.http.patch<CompanyDetailResponse>(`${environment.apiBaseUrl}/api/v1/profiles/company/${uuid}`, request, { params: httpParams });
    }
    return this.http.post<CompanyDetailResponse>(`${environment.apiBaseUrl}/api/v1/profiles/company`, request, { params: httpParams });
  }

  getSetup(uuid: string, merged = false, forEditing = false, profileType?: ProfileTypeEnum | undefined): Observable<GenericSetupResponse> {
    let httpParams = new HttpParams();
    if (merged) {
      httpParams = httpParams.set('merged', true);
    }
    if (forEditing) {
      httpParams = httpParams.set('forEditing', true);
    }
    if (profileType) {
      httpParams = httpParams.set('profileType', profileType);
    }
    return this.http.get<GenericSetupResponse>(`${environment.apiBaseUrl}/api/v1/configuration/setup/company/${uuid}`, { params: httpParams });
  }

  saveSetup(agencyUuid: string, setup: GenericSetupResponse): Observable<GenericSetupResponse> {
    return this.http.patch<GenericSetupResponse>(`${environment.apiBaseUrl}/api/v1/configuration/setup/company/${agencyUuid}`, setup);
  }

  uploadSetup(agencyUuid: string, file: File): Observable<GenericSetupResponse> {
    const formData = new FormData();
    formData.append('setupFile', file, file.name);
    return this.http.patch<GenericSetupResponse>(`${environment.apiBaseUrl}/api/v1/configuration/setup/company/${agencyUuid}/upload`, formData);
  }

  getDeleteNumbers(uuid: string): Observable<CompanyDeleteNumbers> {
    return this.http.get<CompanyDeleteNumbers>(`${environment.apiBaseUrl}/api/v1/profiles/company/${uuid}/delete-count`);
  }

  delete(uuid: string): Observable<void> {
    return this.http.delete<void>(`${environment.apiBaseUrl}/api/v1/profiles/company/${uuid}`);
  }

  getPublishStates(companyUuid: string): Observable<ProfilePublishState[]> {
    const httpParams = new HttpParams().set('sections', CompanySection.PUBLISH_STATES);
    return this.http.get<CompanyDetailResponse>(`${environment.apiBaseUrl}/api/v1/profiles/company/${companyUuid}`, { params: httpParams })
      .pipe(map(profile => profile.data?.publishStates || []));
  }

  deletePublishState(companyUuid: string, interfaceUuid: string, disconnectTravellers = false): Observable<void> {
    const httpParams = new HttpParams().set('disconnectTravellers', disconnectTravellers);
    return this.http.delete<void>(`${environment.apiBaseUrl}/api/v1/profiles/company/${companyUuid}/publishstate/${interfaceUuid}`, { params: httpParams });
  }

  getSubsidiaries(uuid: string): Observable<CompanySearchResult[]> {
    return this.http.get<CompanySearchResult[]>(`${environment.apiBaseUrl}/api/v1/profiles/company/${uuid}/subsidiaries`);
  }

  getAdministrators(uuid: string): Observable<CompanyAdministratorDetail[]> {
    return this.http.get<CompanyAdministratorDetail[]>(`${environment.apiBaseUrl}/api/v1/profiles/company/${uuid}/administrators`);
  }

  toggleSelfRegistration(uuid: string, enable: boolean): Observable<any> {
    const httpParams = new HttpParams().set('enable', enable);
    return this.http.post(`${environment.apiBaseUrl}/api/v1/profiles/company/${uuid}/toggle-selfreg`, {}, { params: httpParams });
  }

  buildSelfRegistrationLink(uuid: string, request: SelfRegistrationLinkGenerationRequest): Observable<SelfRegistrationLink> {
    return this.http.patch<SelfRegistrationLink>(`${environment.apiBaseUrl}/api/v1/profiles/company/${uuid}/create-selfreg-link`, request);
  }

  searchAttribute(companyUuid: string | undefined, interfaceUuid: string, endpoint: string, params: any): Observable<AutoCompletableDataEntry[]> {
    params = {
      ...params,
      intf: interfaceUuid
    };

    if (companyUuid) {
      params.comp = companyUuid;
    }

    const httpParams = new HttpParams({ fromObject: params });
    return this.http.get<AutoCompletableDataEntry[]>(`${environment.apiBaseUrl}/api/v1/profiles/company/autocomplete/${endpoint}`, { params: httpParams })
  }

  getAttribute(value: string, interfaceUuid: string, companyUuid: string | undefined, resourcePath: string, params: any): Observable<AutoCompletableDataEntry> {
    params = {
      ...params,
      v: value,
      intf: interfaceUuid,
      comp: companyUuid
    }
    const httpParams = new HttpParams({ fromObject: params });
    return this.http.get<AutoCompletableDataEntry>(`${environment.apiBaseUrl}/api/v1/profiles/company/attrlookup/${resourcePath}`, { params: httpParams })
  }

  getSensitiveInterfaceConfiguration(companyUuid: string, interfaceUuid: string, attributeIndex: number): Observable<string> {
    return this.http.get<string>(`${environment.apiBaseUrl}/api/v1/secrets/company/${companyUuid}/interface/${interfaceUuid}/attribute/${attributeIndex}`);
  }

  getEvolviOrganisationDetail(companyUuid: string, detailType: 'units' | 'accounts' | 'policies'): Observable<GenericFieldWizardDataEntry[]> {
    return this.http.get<GenericFieldWizardDataEntry[]>(`${environment.apiBaseUrl}/api/v1/profiles/company/${companyUuid}/evolvi_${detailType}.json`);
  }

  getTravellerProfileCount(companyUuid: string): Observable<number> {
    return this.http.get<number>(`${environment.apiBaseUrl}/api/v1/profiles/company/${companyUuid}/traveller-count`);
  }
}
